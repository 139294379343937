/* eslint-disable array-callback-return */
import _ from 'lodash';
import React from 'react';
import { Table, Label, Icon } from 'semantic-ui-react';
import moment from 'moment';
import ExportJsonExcel from 'js-export-excel';

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const onReset = () => {
  this.setState({
    ...this.initialState
  });
};

export const capitalize = (cadena, separador = ' ') => {
  return cadena
    .split(separador)
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
    .join(separador);
};

/**
 * @param formValues Object
 * @param paramsMap Object
 */
export const extraerParametrosBusqueda = (formValues, paramsMap) => {
  return Object.keys(formValues)
    .map((key) => {
      if (formValues[key] && formValues[key] !== -1) {
        switch (key) {
          case 'desde':
            return `${paramsMap[key]}=${formValues[key]} 00:00:00`;
          case 'hasta':
            return `${paramsMap[key]}=${formValues[key]} 23:59:59`;
          default:
            return `${paramsMap[key]}=${formValues[key]}`;
        }
      }
    })
    .filter((item) => item);
};

export const extraerOpcionesOperadores = (operador) => ({
  key: operador.id,
  text: operador.nombre,
  value: operador.id
});

export const extraerOpcionesAdministrativos = (administrativo) => ({
  key: administrativo.id,
  text: administrativo.nombre,
  value: administrativo.id
})

export const extraerOpcionesProveedores = (proveedor) => ({
  key: proveedor.id,
  text: proveedor.nombre,
  value: proveedor.id
});

export const extraerOpcionesClientes = (cliente) => {
  return { key: cliente.id, text: cliente.nombreEmpresa, value: cliente.id };
};

export const extraerOpcionesRutas = (ruta) => {
  return { key: ruta.id, text: ruta.nombre, value: ruta.id };
};

export const extraerOpcionesEstructuras = (estructura) => {
  return {
    key: estructura.id,
    text: estructura.detalle(),
    value: estructura.id
  };
};

export const filtrarClientesFromServicios = (servicios, clientes) => {
  const idsClientes = _.uniq(
    servicios.map((servicio) => {
      return servicio.idRuta ? servicio.idCliente : -1;
    })
  );

  // console.log(servicios, clientes, idsClientes);

  return clientes
    .filter((cliente) => idsClientes.includes(cliente.id))
    .map(extraerOpcionesClientes);
};

/**
 * Generate the headers of a table from a List of Strings
 * @param {Array} xs List of Strings
 * TODO: Handle Icons, Colspans
 */
// eslint-disable-next-line camelcase
export const table_header = (xs) => (
  <Table.Header>
    <Table.Row>
      {xs.map((x, idx) => (
        <Table.HeaderCell key={idx}>{x}</Table.HeaderCell>
      ))}
    </Table.Row>
  </Table.Header>
);

export const getDias = (state) => {
  const dias = [];

  if (state.lunes) {
    dias.push(1);
  }

  if (state.martes) {
    dias.push(2);
  }

  if (state.miercoles) {
    dias.push(3);
  }

  if (state.jueves) {
    dias.push(4);
  }

  if (state.viernes) {
    dias.push(5);
  }

  if (state.sabado) {
    dias.push(6);
  }

  if (state.domingo) {
    dias.push(7);
  }

  return dias;
};

export const getStatusServicio = (servicio) => {
  if (servicio.cancelado) {
    return (
      <Label color='grey' size='tiny' horizontal>
        Cancelado
      </Label>
    );
  }

  if (
    moment(servicio.tiempoInicial).diff(moment(), 'minutes') < 30 &&
    !servicio.confirmado
  ) {
    return (
      <Label color='red' size='tiny' horizontal>
        Sin confirmar
      </Label>
    );
  }

  switch (servicio.estatus) {
    case 1:
      return (
        <Label color='green' size='tiny' horizontal>
          En ruta
        </Label>
      );
    case 2:
      return (
        <Label color='grey' size='tiny' horizontal>
          Finalizado
        </Label>
      );
    default:
      return (
        <Label color='blue' size='tiny' horizontal>
          Programado
        </Label>
      );
  }
};

export const getIconoTipoServicio = (servicio) => {
  switch (servicio.modalidad) {
    case 'Empresarial':
      return <Icon circular color='grey' name='bus' />;
    case 'Especial':
      return <Icon circular color='violet' name='bus' />;
    default:
      return <Icon circular color='black' name='bus' />;
  }
};

export const roundToTwoDecimals = (value) => {
  return Math.round(value * 100) / 100;
};

export const downloadExcel = (data, name) => {
  const option = {};

  const dataReportePagar = [];
  const dataReporteNoPagar = [];
  const dataReporteBitacorasGPS = [];
  const dataReporteTikets = [];
  let numero = 0;

  if (data.all.length > 0) {
    data.all.map((d) => {
      //const fecha = d.fecha.slice(0, 11);
      let fecha = null
      if(d.fecha){
        fecha = d.fecha.slice(0, 11);
      }
      //const fecha = d.fecha.slice(0, 11);
      let objBitacoras = {};
      let objTickets = {};
      let nombreColaborador = '';

      if(d.operador){
        nombreColaborador = d.operador ? d.operador.nombre : d.administrativo.nombre;
      }

      if(d.administrativo){
        nombreColaborador = d.administrativo ? d.administrativo.nombre : d.operador.nombre;
      }
      
      switch (name) {
        case 'th':
          objBitacoras = {
            numero: (numero += 1),
            bitacora: d.id,
            folio: d.folioBitacora ? d.folioBitacora : 'Sin datos',
            fecha: fecha,
            operador: d.operador ? d.operador.nombre : 'Sin datos',
            ruta: d.estructura.attributes.nombreRuta,
            aforo: d.numeroPersonas ? d.numeroPersonas : 'Sin datos',
            unidad: d.unidad ? d.unidad.id : 'Sin datos',
            kmInicial: d.kilometrajeInicial
              ? d.kilometrajeInicial
              : 'Sin datos',
            kmFinal: d.kilometrajeFinal ? d.kilometrajeFinal : 'Sin datos',
            confirmado: d.attributes.confirmado === true ? 'Si' : 'No',
            transferido: d.transferido === true ? 'Si' : 'No',
            turno: d.estructura.attributes.turno,
            tipoEstructura: d.estructura.attributes.tipo,
            tipoBitacora: d.tipo,
            pagoChofer: d.attributes.pagoChofer
          };

          if (d.attributes.pagarServicio === true) {
            dataReportePagar.push(objBitacoras);
          } else {
            dataReporteNoPagar.push(objBitacoras);
          }
          break;
        case 'gps':
          objBitacoras = {
            numero: (numero += 1),
            bitacora: d.id,
            folio: d.folioBitacora ? d.folioBitacora : 'Sin datos',
            fecha: fecha,
            operador: d.operador ? d.operador.nombre : 'Sin datos',
            ruta: d.estructura.attributes.nombreRuta,
            aforo: d.numeroPersonas ? d.numeroPersonas : 'Sin datos',
            unidad: d.unidad ? d.unidad.id : 'Sin datos',
            kmInicial: d.kilometrajeInicial
              ? d.kilometrajeInicial
              : 'Sin datos',
            kmFinal: d.kilometrajeFinal ? d.kilometrajeFinal : 'Sin datos',
            confirmado: d.attributes.confirmado === true ? 'Si' : 'No',
            transferido: d.transferido === true ? 'Si' : 'No',
            turno: d.estructura.attributes.turno,
            tipo: d.estructura.attributes.tipo,
            pagoChofer: d.attributes.pagoChofer
          };

          dataReporteBitacorasGPS.push(objBitacoras);
          break;
        case 'tikets':
          objTickets = {
            id: d.id,
            colaborador: nombreColaborador,
            gasolinera: d.gasolinera.nombre,
            unidad: d.attributes.idUnidad,
            kilometraje: d.attributes.kilometraje,
            folio: d.attributes.folio,
            fecha: fecha,
            tipoCombustible: d.attributes.tipoCombustible
              ? d.attributes.tipoCombustible
              : 'Sin datos',
            litros: d.attributes.litros,
            precio: d.attributes.precioCombustible,
            monto: d.attributes.monto,
            partida: d.attributes.idPartida,
            via_captura: d.attributes.viaCaptura === 0 ? 'App' : 'Admin'
          };
          dataReporteTikets.push(objTickets);
          break;
        default:
          console.log(name);
      }
    });
  }

  switch (name) {
    case 'th':
      option.fileName = 'Reporte Bitacora ' + moment().format('YYYY-MM-DD');

      option.datas = [
        {
          sheetData: dataReportePagar,
          sheetName: 'Pagar',
          sheetFilter: [
            'numero',
            'fecha',
            'bitacora',
            'confirmado',
            'transferido',
            'operador',
            'ruta',
            'tipoEstructura',
            'tipoBitacora',
            'pagoChofer'
          ],
          sheetHeader: [
            'No.',
            'Fecha',
            'Bitacora',
            'Confrimado',
            'Transferido',
            'Operador',
            'Ruta',
            'Tipo Estructura',
            'Tipo Bitacora',
            'Pago Chofer'
          ],
          columnWidths: [2, 4, 4, 4, 4, 14, 16, 6, 6, 4]
        },
        {
          sheetData: dataReporteNoPagar,
          sheetName: 'No pagar',
          sheetFilter: [
            'numero',
            'fecha',
            'bitacora',
            'confirmado',
            'transferido',
            'operador',
            'ruta',
            'tipoEstructura',
            'tipoBitacora',
            'pagoChofer'
          ],
          sheetHeader: [
            'No.',
            'Fecha',
            'Bitacora',
            'Confrimado',
            'Transferido',
            'Operador',
            'Ruta',
            'Tipo Estructura',
            'Tipo Bitacora',
            'Pago Chofer'
          ],
          columnWidths: [2, 4, 4, 4, 4, 14, 16, 6, 6, 4]
        }
      ];
      break;
    case 'gps':
      option.fileName =
        'Reporte Bitacoras GPS ' + moment().format('YYYY-MM-DD');

      option.datas = [
        {
          sheetData: dataReporteBitacorasGPS,
          sheetName: 'Reporte bitacoras',
          sheetFilter: [
            'bitacora',
            'folio',
            'fecha',
            'operador',
            'ruta',
            'aforo',
            'unidad',
            'kmInicial',
            'kmFinal'
          ],
          sheetHeader: [
            'Bitacora',
            'Folio',
            'Fecha',
            'Operador',
            'Ruta',
            'Aforo',
            'Unidad',
            'Km inicial',
            'Km final'
          ],
          columnWidths: [4, 3, 4, 14, 16, 4, 3, 5, 5]
        }
      ];
      break;
    case 'tikets':
      option.fileName = 'Reporte Tikets ' + moment().format('YYYY-MM-DD');

      option.datas = [
        {
          sheetData: dataReporteTikets,
          sheetName: 'Reporte Tikets',
          sheetFilter: [
            'fecha',
            'id',
            'colaborador',
            'gasolinera',
            'unidad',
            'kilometraje',
            'folio',
            'tipoCombustible',
            'litros',
            'precio',
            'monto',
            'partida',
            'via_captura'
          ],
          sheetHeader: [
            'Fecha',
            'Id',
            'Colaborador',
            'Gasolinera',
            'Unidad',
            'Kilometraje',
            'Folio',
            'Combustible',
            'Litros',
            'Precio',
            'Monto',
            'Partida',
            'Vía captura'
          ],
          columnWidths: [4, 4, 14, 14, 3, 5, 4, 4, 3, 3, 4, 3, 4]
        }
      ];
      break;
    default:
      console.log(name);
  }

  const toExcel = new ExportJsonExcel(option);
  toExcel.saveExcel();
};

// EQUIPOS CELULARES
export const extraerOpcionesMarcasEquiposCelulares = (equipos) => ({
  key: equipos.ID,
  text: equipos.MARCA,
  value: equipos.ID,
});

export const extraerOpcionesModelosEquiposCelulares = (modelos) => ({
  key: modelos.ID,
  text: modelos.MODELO,
  value: modelos.ID
});

export const extraerOpcionesLineasCelulares = (lineas) => ({
  key: lineas.ID,
  text: lineas.TELEFONO,
  value: lineas.ID
});

export const extraerOpcionesProveedoresEquiposCelulares = (proveedor) => ({
  key: proveedor.data.id,
  text: proveedor.data.attributes.nombre,
  value: proveedor.data.id
});

export const extraerOpcionesPlanesEquiposCelulares = (plan) => ({
  key: plan.ID,
  text: plan.NOMBRE_PLAN,
  value: plan.ID
});

export const extraerOpcionesEstatusEquiposCelulares = (estatus) => ({
  key: estatus.ID,
  text: estatus.NOMBRE,
  value: estatus.ID
});

export const extraerOpcionesEquiposCelulares = (equipos) => ({
  key: equipos.ID,
  text: equipos.IMEI,
  value: equipos.ID
});

export const extraerOpcionesUsuariosEquiposCelulares = (usuarios) => ({
  key: usuarios.ID_USUARIO,
  text: usuarios.NOMBRE,
  value: usuarios.ID_USUARIO
});

export const condicionEquipo = [
  'Asingar',
  'Reemplazar',
  'Liberar',
].map((condicion, idx) => {
return { key: idx, value: condicion, text: condicion }
})

export const equipoAtivo = [
  { key: 0, value: '0', text:'Inactivo' },
  { key: 1, value: '1', text:'Activo' }
]

export const downloadExcelCelulares = (data, name) => {
  const option = {};

  const dataReporteCelulares = [];

  if (data.length > 0) {
    data.map((d) => {
      // let fecha = null

      // if(d.fecha){
      //   fecha = d.fecha.slice(0, 11);
      // }

      let objCelulares = {};

      switch (name) {
          case 'celulares':
            objCelulares = {
              id: d.ID,
              nombre: d.NOMBRE,
              correo: d.EMAIL,
              imei: d.IMEI,
              modelo: d.MODELO_NOMBRE,
              linea: d.TELEFONO,
            };

            dataReporteCelulares.push(objCelulares);
          break;
        default:
          console.log(name);
      }
    });
  }

  switch (name) {
    case 'celulares':
      option.fileName = 'Resultado de búsqueda celulares' + moment().format('YYYY-MM-DD');

      option.datas = [
        {
          sheetData: dataReporteCelulares,
          sheetName: 'Resultado de búsqueda celulares',
          sheetFilter: [
            'id',
            'nombre',
            'correo',
            'imei',
            'modelo',
            'linea',
          ],
          sheetHeader: [
            'Id',
            'Nombre',
            'Correo',
            'IMEI',
            'Modelo',
            'Línea',
          ],
          columnWidths: [2, 12, 12, 7, 8, 8]
        }
      ];
      break;
    default:
      console.log(name);
  }

  const toExcel = new ExportJsonExcel(option);
  toExcel.saveExcel();
};

export const downloadExcelHistorialCelulares = (data, name) => {
  const option = {};

  const dataReporte = [];

  if (data.length > 0) {
    data.map((d) => {
      let fecha = null

      if(d.FECHA_MOVIIENTO){
        fecha = d.FECHA_MOVIIENTO.slice(0, 11);
      }

      let objHistorial = {};

      switch (name) {
        case 'historial':
          objHistorial = {
            id: d.ID,
            fechaEvento: fecha,
            usuario: d.NOMBRE,
            accion: d.ACCION,
            imei: d.IMEI,
            linea: d.TELEFONO,
          };
          dataReporte.push(objHistorial)
          break;
        default:
          console.log(name);
      }
    });
  }

  switch (name) {
    case 'historial':
      option.fileName = 'Reporte Historial ' + moment().format('YYYY-MM-DD');

      option.datas = [
        {
          sheetData: dataReporte,
          sheetName: 'Historial',
          sheetFilter: [
            'id',
            'fechaEvento',
            'usuario',
            'accion',
            'imei',
            'linea',
          ],
          sheetHeader: [
            'Id',
            'Fecha del evento',
            'Usuario',
            'Acción realizada',
            'IMEI equipo',
            'Línea',
          ],
          columnWidths: [2, 5, 10, 6, 6, 6]
        },
      ];
      break;
    default:
      console.log(name);
  }

  const toHistoryExcel = new ExportJsonExcel(option);
  toHistoryExcel.saveExcel();
};

// PERMISOS
//Tipos de usuarios
export const tipoUsuario = [
  {
    "tipo": "Desarrollo",
    "ids": [1, 4182, 3746, 3810, 3794, 3965, 4079,  4325,4681],
    "permisos": {
      "permisosGenerales": true,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": false,
      "permisosMinimos": false,
    }
  },
  {
    "tipo": "Gerente Administracion",
    "ids": [4303, 4086], //Eduardo, Sonia
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": true,
      "permisosBasicos": false,
      "permisosMinimos": false,
    }
  },
  {
    "tipo": "Administracion",
    "ids": [1445, 3966,4457], //Fernando, Margarita
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": true,
      "permisosMinimos": false,
    }
  },
  {
    "tipo": "Procesos",
    "ids": [4250], //Juan Diego
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": false,
      "permisosMinimos": true,
    }
  },
  {
    "tipo": "Auxiliar administrativo",
    "ids": [4156, 4315], //Karen,Evelyn
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": false,
      "permisosMinimos": true,
    }
  },
  {
    "tipo": "Nominas",
    "ids": [4239,4652], //Fernanda
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": false,
      "permisosMinimos": true,
      
    }
  },
  {
    "tipo": "Talento humano",
    "ids": [4187,4057, 4112,4497,3705], //Miriam Huante, Brenda
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": true,
      "permisosMinimos": false,
    }
  },
  {
    "tipo": "Operaciones",
    "ids": [3690, 4321, 1605, 4322], //Ismael, Ximena, Ramon, Oswaldo
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": false,
      "permisosMinimos": true,
    }
  },
  // {
  //   "tipo": "Reclutamiento",
  //   "ids": [4289], //Erik
  //   "permisos": {
  //     "permisosGenerales": false,
  //     "permisosBasicosConPrecios": false,
  //     "permisosBasicos": false,
  //     "permisosMinimos": true,
  //   }
  // },
  // {
  //   "tipo": "Atencion clientes",
  //   "ids": [4201, 1478, 2624, 3705, 3957, 4027, 4085, 3837], //Vanesa, Vero, Yazmin, Alma, Johana, Ana, Judith, Sharon
  //   "permisos": {
  //     "permisosGenerales": false,
  //     "permisosBasicosConPrecios": false,
  //     "permisosBasicos": true,
  //     "permisosMinimos": false,
  //   }
  // },
  {
    "tipo": "GPS",
    "ids": [4122, 3688, 4452,4514,4475,4464,4542], // María Luisa, Emi, Jose Luis,grecia fernanda alonzo rodriguez(4514),Miguel Sanchez (4475),javier sandival(4464)
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": true,
      "permisosMinimos": false,
    }
  },
  {
    "tipo": "Hardware",
    "ids": [3809], // Hector
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": false,
      "permisosMinimos": true,
    }
  },
  {
    "tipo": "General",
    "ids": [4187, 3857, 3927, 3840, 3906, 3907, 3825, 3908, 3930, 3958, 3969, 3973, 4001, 4084, 4157, 3689, 4052, 4103, 3743, 3732, 146, 3819, 159, 3738, 3864, 166, 158, 3780,
      393, 1596, 3857, 3646, 3948, 3639, 342, 3949, 1478, 2624, 3837, 3705, 3921, 3844, 3706, 2624, 4027, 4085, 3821, 3813, 3937, 4114, 4125, 4542  ], // Todos
    "permisos": {
      "permisosGenerales": false,
      "permisosBasicosConPrecios": false,
      "permisosBasicos": false,
      "permisosMinimos": false,
    }
  }
]

/* Función que valida si el usuario logueado pertenece a un tipo
de usuario y obtenemos sus permisos */
export const idUsuarios = (tipos, usuario) => {

  let typeUser = '';
  let permisos = '';
  const fechaLogin = moment().format("YYYY-MM-DD")

  for (let tipo of tipos) {
    const idTipo = tipo.ids.includes(usuario.id)

    if (idTipo === true) {
      typeUser = tipo.tipo
      permisos = tipo.permisos

      return (
        usuario.typeUser = typeUser,
        usuario.permisos = permisos,
        usuario.fechaLogin = fechaLogin
      )
    } else {
      typeUser = 'Test';
    }
  }
}

// HISTORIAL PRECIOS GASOLINA
export const downloadExcelHistorialGasolina = (data, name) => {

  const option = {};

  const dataReporte = [];

  if (data.length > 0) {
    data.map((d) => {
      // let fecha = null

      // if(d.FECHA){
      //   fecha = d.FECHA.slice(0, 11);
      // }

      let objHistorial = {};

      switch (name) {
        case 'historialGasolinas':
          objHistorial = {
            id: d.ID,
            fecha: d.FECHA,
            magna: d.MAGNA ? d.MAGNA : 'Sin actualización',
            premium: d.PREMIUM  ? d.PREMIUM : 'Sin actualización',
            diesel: d.DIESEL  ? d.DIESEL : 'Sin actualización',
            usuario: d.NOMBRE
          };
          dataReporte.push(objHistorial)
          break;
        default:
          console.log(name);
          break;
      }
    });
  }

  switch (name) {
    case 'historialGasolinas':
      option.fileName = 'Reporte Historial Gasolina ' + moment().format('YYYY-MM-DD');

      option.datas = [
        {
          sheetData: dataReporte,
          sheetName: 'Historial Gasolina',
          sheetFilter: [
            'id',
            'fecha',
            'magna',
            'premium',
            'diesel',
            'usuario'
          ],
          sheetHeader: [
            'Id',
            'Fecha',
            'Magna',
            'Premium',
            'Diesel',
            'Usuario'
          ],
          columnWidths: [2, 6, 5, 5, 5, 6]
        },
      ];
      break;
    default:
      console.log(name);
  }

  const toHistoryExcel = new ExportJsonExcel(option);
  toHistoryExcel.saveExcel();
};

//REPORTE PAGO A OPERADORES POR RANGO DE FECHA
export const downloadExcelPagoOperadores = (data, name) => {
  const option = {};
  const dataReporte = [];

  if (data.length > 0) {
    data.map((d) => {
      let objLista = {};

      switch (name) {
        case 'listaPorFechas':
          objLista = {
            id_operador: d.ID_OPERADOR,
            operador: d.OPERADOR,
            tipo_viaje: d.TIPO_VIAJE,
            nombre_ruta: d.NOMBRE_RUTA,
            tipo: d.TIPO,
            km_ruta_unitario: d.KM_RUTA_UNITARIO,
            unidad_tipo: d.UNIDAD_TIPO,
            pago_op_unitario: d.PAGO_CHOFER_UNITARIO,
            num_viajes: d.NO_VIAJES,
            km_total: d.KM_TOTAL,
            total_pago_op: d.TOTAL_PAGO_CHOFER,
            sueldo_garantia: d.SUELDO_GARANTIA,
            dias: d.DIAS,
            domingo: d.DOMINGO === 1 ? 'Si':'No',
          };
          dataReporte.push(objLista)
          break;
        default:
          console.log(name);
          break;
      }
    });
  }

  switch (name) {
    case 'listaPorFechas':
      option.fileName = 'Reporte Pago Operadores ' + moment().format('YYYY-MM-DD');

      option.datas = [
        {
          sheetData: dataReporte,
          sheetName: 'Pago a Operadores por fecha',
          sheetFilter: [
            'id_operador',
            'operador',
            'tipo_viaje',
            'nombre_ruta',
            'tipo',
            'km_ruta_unitario',
            'unidad_tipo',
            'pago_op_unitario',
            'num_viajes',
            'km_total',
            'total_pago_op',
            'sueldo_garantia',
            'dias',
            'domingo',
          ],
          sheetHeader: [
            'Id Op.',
            'Nombre',
            'Tipo de viaje',
            'Ruta',
            'Tipo',
            'Km Ruta',
            'Tipo de unidad',
            'Pago Op.',
            'No. Viajes',
            'Km total',
            'Total Pago Op.',
            'Sueldo garantía',
            'Días',
            'Domingo',
          ],
          columnWidths: [3, 16, 6, 20, 4, 4, 7, 5, 4, 4, 6, 6, 2, 4]
        },
      ];
      break;
    default:
      console.log(name);
  }

  const toHistoryExcel = new ExportJsonExcel(option);
  toHistoryExcel.saveExcel();
}

//REPORTE PAGO A OPERADOR POR RANGO DE FECHA
export const downloadExcelPagoOperador = (data, name) => {
  const option = {};
  const dataReporteEmpresarial = [];
  const dataReporteEspeciales = [];
  const dataReporteTaller = [];

  const empresarial = data.EMPRESARIAL.BITACORAS
  const especiales = data.ESPECIALES.BITACORAS
  const taller = data.TALLER.BITACORAS

  if (empresarial.length > 0 || especiales.length > 0 || taller.length > 0) {
    let objLista = {};

    data.EMPRESARIAL.BITACORAS.map(item => {
        switch (name) {
          case 'listaPorOperador':
            objLista = {
              id: data.ID_OPERADOR,
              operador: data.OPERADOR,
              sueldo_garantia: data.SUELDO_GARANTIA,
              dias_trabajados: data.DIAS_TRABAJADOS,
              domingo_trabajado: data.DOMINGO_TRABAJADO,
              km_ruta_unitario: item.KM_RUTA_UNITARIO,
              km_total: item.KM_TOTAL,
              ruta: item.NOMBRE_RUTA,
              no_viajes: item.NO_VIAJES,
              pago_op_unitario: item.PAGO_CHOFER_UNITARIO,
              tipo: item.TIPO,
              total_pago_chofer: item.TOTAL_PAGO_CHOFER,
              tipo_unidad: item.UNIDAD_TIPO,
            };
            dataReporteEmpresarial.push(objLista)
            break;
          default:
            console.log(name);
            break;
        }
    })

    data.ESPECIALES.BITACORAS.map(item => {
      switch (name) {
        case 'listaPorOperador':
          objLista = {
            id: data.ID_OPERADOR,
            operador: data.OPERADOR,
            sueldo_garantia: data.SUELDO_GARANTIA,
            dias_trabajados: data.DIAS_TRABAJADOS,
            domingo_trabajado: data.DOMINGO_TRABAJADO,
            km_ruta_unitario: item.KM_RUTA_UNITARIO,
            km_total: item.KM_TOTAL,
            ruta: item.NOMBRE_RUTA,
            no_viajes: item.NO_VIAJES,
            pago_op_unitario: item.PAGO_CHOFER_UNITARIO,
            tipo: item.TIPO,
            total_pago_chofer: item.TOTAL_PAGO_CHOFER,
            tipo_unidad: item.UNIDAD_TIPO,
          };
          dataReporteEspeciales.push(objLista)
          break;
        default:
          console.log(name);
          break;
      }
    })

    data.TALLER.BITACORAS.map(item => {
      switch (name) {
        case 'listaPorOperador':
          objLista = {
            id: data.ID_OPERADOR,
            operador: data.OPERADOR,
            sueldo_garantia: data.SUELDO_GARANTIA,
            dias_trabajados: data.DIAS_TRABAJADOS,
            domingo_trabajado: data.DOMINGO_TRABAJADO,
            km_ruta_unitario: item.KM_RUTA_UNITARIO,
            km_total: item.KM_TOTAL,
            ruta: item.NOMBRE_RUTA,
            no_viajes: item.NO_VIAJES,
            pago_op_unitario: item.PAGO_CHOFER_UNITARIO,
            tipo: item.TIPO,
            total_pago_chofer: item.TOTAL_PAGO_CHOFER,
            tipo_unidad: item.UNIDAD_TIPO,
          };
          dataReporteTaller.push(objLista)
          break;
        default:
          console.log(name);
          break;
      }
    })
  }

  switch (name) {
    case 'listaPorOperador':
      option.fileName = 'Reporte Pago Operador ' + moment().format('YYYY-MM-DD');

      option.datas = [
        {
          sheetData: dataReporteEmpresarial,
          sheetName: 'Empresarial',
          sheetFilter: [
            'id',
            'operador',
            'sueldo_garantia',
            'dias_trabajados',
            'domingo_trabajado',
            'total_pago_chofer',
            'km_total',
            'no_viajes',
            'ruta',
            'km_ruta_unitario',
            'tipo',
            'tipo_unidad',
            'pago_op_unitario',
          ],
          sheetHeader: [
            'Id Op.',
            'Nombre',
            'Sueldo garantía',
            'Días trabajados',
            'Domingo',
            'Total pago Operador',
            'Km total',
            'No. Viajes',
            'Ruta',
            'Km ruta unitario',
            'Tipo',
            'Unidad',
            'Pago unitario'
          ],
          columnWidths: [3, 16, 6, 6, 6, 7, 5, 4, 16, 4, 8, 8, 5]
        },
        {
          sheetData: dataReporteEspeciales,
          sheetName: 'Especiales',
          sheetFilter: [
            'id',
            'operador',
            'sueldo_garantia',
            'dias_trabajados',
            'domingo_trabajado',
            'total_pago_chofer',
            'km_total',
            'no_viajes',
            'ruta',
            'km_ruta_unitario',
            'tipo',
            'tipo_unidad',
            'pago_op_unitario',
          ],
          sheetHeader: [
            'Id Op.',
            'Nombre',
            'Sueldo garantía',
            'Días trabajados',
            'Domingo',
            'Total pago Operador',
            'Km total',
            'No. Viajes',
            'Ruta',
            'Km ruta unitario',
            'Tipo',
            'Unidad',
            'Pago unitario'
          ],
          columnWidths: [3, 16, 6, 6, 6, 7, 5, 4, 16, 4, 8, 8, 5]
        },
        {
          sheetData: dataReporteTaller,
          sheetName: 'Taller',
          sheetFilter: [
            'id',
            'operador',
            'sueldo_garantia',
            'dias_trabajados',
            'domingo_trabajado',
            'total_pago_chofer',
            'km_total',
            'no_viajes',
            'ruta',
            'km_ruta_unitario',
            'tipo',
            'tipo_unidad',
            'pago_op_unitario',
          ],
          sheetHeader: [
            'Id Op.',
            'Nombre',
            'Sueldo garantía',
            'Días trabajados',
            'Domingo',
            'Total pago Operador',
            'Km total',
            'No. Viajes',
            'Ruta',
            'Km ruta unitario',
            'Tipo',
            'Unidad',
            'Pago unitario'
          ],
          columnWidths: [3, 16, 6, 6, 6, 7, 5, 4, 16, 4, 8, 8, 5]
        },
      ];
      break;
    default:
      console.log(name);
  }

  const toHistoryExcel = new ExportJsonExcel(option);
  toHistoryExcel.saveExcel();
}